import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "components/layout.js"
import SEO from "components/seo-component.js" 
import DirectoryCategories from "components/content/categories" 
import GridIcons from "components/content/gridicons" 

const BusinessPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query ProviderPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
  <Layout>
    <SEO title="For Business and Service Providers" />

    <div className="page-header">
            <div className="container">
      {/* <img src={SlackDataPolicyTrustImage}/> */}

      {/* <img data-src="holder.js/500x100?theme=gray" /> */}
      <h2>DataPolicyTrust &amp; Your Tech Company</h2>
      <p>
        Your customers need better intelligence on your services and solutions {' '}
        {/* <br/> */}
        &amp; your teams need to build more trust with customers.
      </p>
       <div className="col-xs-auto">
                            <img   alt="DataPolicyTrust.com" src="/images/b2bscreenshot1.png" className="center-block  img-responsive mx-auto" />
                          </div>     </div>

    </div>
{/*     
    <div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Request your Report</h2>
                <p>
                  Request a report for your technology company to evaluate your standards and to ensure your customers trust your standards.
                </p>
              </div>

              <div className="button">
              <Link to='/request-report' 
                // target="_blank"}
                >
                  Request Report
                </Link>
              </div>
            </div>
          </div> */}


<div className="call-to-action bg-dark2">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  Are you looking to increase trust with customers? We'd like to help.  
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/providers/">Let's talk</a>
              </div>
            </div>
          </div>
    <section className="features features-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
            <h3>
              Highlight your
              organizations privacy, policy &amp; cyber security standards.
            </h3>
            {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
             <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p>    
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center feature">
            <i className="icon-bag" />
            <h5>
              Build Trust
            </h5>
            <p>
            Show how your organization is working towards ensuring data privacy that real people, customers &amp; regulators care
              about. Disclose and showcase personal data privacy parameters
              related to your app or service's privacy policies as well as
               compliance 24/7.
            </p>
          </div>
          <div className="col-md-4 text-center feature">
            <i className="icon-graph" />
            <h5>Highlight Standards</h5>
            <p>
              Based on the most modern web standards, devices, platforms,
              operating systems and eco systems from PC, mobile, IoT, AI, XR &amp; more, highlight how your company handles data protection and trust.
            </p>
          </div>
          <div className="col-md-4 text-center feature">
            <i className="icon-list-thumbnails" />
            <h5>Enable Transparency</h5>
            <p>
              We provide your end users and customers useful and helpful data and
              information on your solutions and services to help them instantly evaluate
              personal data policies and data privacy standards of your team and company.
            </p>
          </div>
        </div>


      </div>
    </section>
   
    <section className="features features-3 mb-6 pb-6">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
            <h3>
              Does your organization collect personal data or care about customer data?
            </h3>

            <p>
              As the largest, open database of privacy ratings and reports in
              the world, our business is making high-quality, official privacy
              data openly available to consumers and customers. Privacy and InfoSec Data that can be
              trusted, accessed, analysed, monitored and reviewed when and how
              it’s needed.
            </p>
          </div>
        </div>
      
      
        <GridIcons/>

         </div>
        <div className="row">
          <div className="col-md-12 text-center pb-4"><br/><br/>
          <Link to="/why" className="btn btn-primary  btn-sm">Learn More about Why DataPolicyTrust Exists?</Link>
          <br/><br/>  </div>

        </div>

    </section>

    <section className="features features-3 mt-6 pt-6">
    <div className="bg-color2">
      <div className="container">
        <div className="integration-guid">
          <div className="title">
            <h2>We provide data to your customers and data on your vendors and many more...</h2>
            <br/>
            <br/>
          </div>
        <DirectoryCategories/>
          <br />
          <br />
        </div>
      </div>
    </div>
</section>
 
<section className="features features-3">
            <div className="bg-color2">
              <div className="container">
                <div className="row"> 
                
                   <div className="col-md-12 col-md-offset-1b col-sm-12 text-centerl">
        
                  <div className="title2">  
              
                    <h2>People care about privacy & security in your tech, does your company care?


                    </h2>
                    <br />
                    <br />

                    {/* SIGN UP TO GET NOTIFIED ABOUT OUR UPCOMING BETA! Request to claim or register the technology profiles of solutions you offer like this profile! */}

<p>Learn how to earn more customer trust by learning what they care about in terms of privacy and cyber security in your services and apps.
</p><p>
<h4>Trust is more important than convenience. </h4>

Only DataPolicyTrust provides unbiased Trust Scores based on public information gathered from analyzing the policies and terms of your services.
</p><p>
We help you grow and earn more customer trust by pinpointing unclear and potentially risky parameters and recommendations to users where data privacy and personal privacy terms are in your user agreements and policy documents.
</p><p>
<h4>See answers to questions related to data privacy.</h4>
</p><p>
You will learn how your user's personal privacy parameters relate to your app or service's privacy policies and business model decisions.
</p><p>
<h4>Working together to improve privacy for the modern world</h4>
</p><p>
Based on the most modern web standards, devices, platforms, operating systems and eco systems, you will be able to get more insights into how providers use data over time.
</p><p>
<h4>User Privacy Control</h4>
We enable you to have the most useful and helpful data and information on each app, site or service your organization delivers to the world to help you evaluate personal and data privacy risks and potential liabilities of the technologies you worked so hard to deliver.
</p>
                  </div>  </div> 
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>
{/* 
    <div className="bg-color">
      <div className="container">
        <div className="integration-guid">
          <div className="title">
            <h2>How to use DataPolicyTrust for Business</h2>
          </div>

          <div className="content">
            <h4>Overview</h4>
            <p>
              As a service provider or business customer, you can use the site
              for free just like end users can but there's additional features
              and benefits for you.
            </p>
            <h4>Step 1</h4>
            <p>
              You can also search for any vendor, product, site, app, service,
              solution or company that's in our database.
            </p>
            

            <h4>Step 2</h4>
            <p>
              You can then view the profile of the company in question and
              relevant information, tools and data at your finger tips.
            </p>
            
            <h4>Step 3</h4>
            <p>
              You can also view more in depth business-only information if you
              or your team members create an account and save the profile in
              your dashboard in your account for future reference and to monitor
              changes to profiles.
            </p>
            
          </div>
        </div>
      </div>
    </div> */}

<div className="call-to-action bg-dark2">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  Are you looking to increase trust with customers? We'd like to help.  
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/providers/">Let's talk</a>
              </div>
            </div>
          </div>
    <div className="call-to-action d-none">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital operations with {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className="button">
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target="_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
  </Layout>
      </>
    )}
  />
)


export default BusinessPage
